<template>
  <div class="words" style>
    <section class="section"></section>
  </div>
</template>
<script>
export default {
  name: 'words'
}
</script>
<style lang="less" scoped>
.words{
    min-height: 800px;
}
</style>
<template>
  <div class="photo">
    <section class="section">
      <el-row>
        <el-col :span="8" v-for="(o, index) in 2" :key="o" :offset="index > 0 ? 2 : 0">
          <el-card :body-style="{ padding: '0px' }">
            <img
              :src="require('@/assets/images/huanghun.jpeg')"
              class="image"
            />
            <div style="padding: 14px;">
              <span>人约黄昏后</span>
              <div class="bottom clearfix">
                <time class="time">{{ currentDate }}</time>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </section>
  </div>
</template>
<script>
  import {dateformat} from '@/plugins/utils';

  export default {
    name: 'photo',
    data() {
      return {
        currentDate: dateformat("Y-m-d")
      }
    },
    created(){

    }
  }
</script>
<style lang="less" scoped>
  .photo {
    min-height: 850px;
  }
</style>

<template>
    <div class="message body-bg">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>留言</span>
            </div>
            <el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign">
                <el-form-item label="昵称">
                    <el-input class="input" v-model="formLabelAlign.name" placeholder="请输入昵称"></el-input>
                </el-form-item>
                <el-form-item label="联系方式">
                    <el-input class="input" v-model="formLabelAlign.region" placeholder="请输入联系方式"></el-input>
                </el-form-item>
                <el-form-item label="留言内容">
                    <el-input class="input" type="textarea" :rows="5" placeholder="请输入您的留言"
                              v-model="formLabelAlign.type"></el-input>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
export default {
  name: 'message',
  data() {
    return {
      labelPosition: 'right',
      formLabelAlign: {
        name: '',
        region: '',
        type: ''
      }
    }
  }
}
</script>
<style lang="less" scoped>
.body-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
  background: url(~@/assets/images/message.jpeg) no-repeat top center;
}

.message {
  min-height: 880px;
  position: relative;
  background-size: cover;

  .box-card {
    width: 480px;
    text-align: center;
    margin: 180px auto 0;
    background-color: #ffffff00;

    .input {
      :deep(.el-input__wrapper) {
        background-color: #ffffff22;
      }

      :deep(.el-textarea__inner) {
        background-color: #ffffff22;
      }
    }
  }
}


</style>

<template>
  <div class="about body-bg">
    <section class="section">
    </section>
  </div>
</template>
<script>
export default {
    name:'about'
}
</script>
<style lang="less" scoped>
.body-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
  background: url(~@/assets/images/about.jpeg) no-repeat top center;
  background-size: cover;
}
.about{
    min-height: 880px;
    position: relative;
    background-size: cover;
}
</style>
